<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-outline card-primary">
          <div class="card-header">
            <h2 class="card-title">Bestellte Artikel</h2>
          </div>

          <div class="card-body">
            <table class="table table-striped table-sm table-bordered">
              <thead class="thead-light">
                <th>Artikelnummer</th>
                <th>Artikelname</th>
                <th>Anzahl</th>
                <th>Storniert</th>
                <th>Preis</th>
                <th>Gesamt</th>
                <th>Steuern</th>
              </thead>

              <tbody>
                <tr v-for="(item) in order.items" :key="item.id" >
                  <td> 
                    <span>{{ item.articleordernumber }}</span>
                  </td>
                  <td>
                    
                    <span>{{ item.name }}</span>
                  </td>

                  <td>
                  
                    <span>{{ item.quantity }}</span>
                  </td>

                  <td>
                    {{  item.canceled }}
                  </td>

                  <td>
                
                    <span>{{ item.price | toCurrency }}</span>
                  </td>
                  <td>
                    <span>{{ item.quantity * item.price | toCurrency }}</span>
                  </td>
                  <td>
                  
                    <span>{{ item.tax_rate }} %</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- <div class="col-md-6">
        <table class="table table-striped">
          <thead>
            <th>Artikelnummer</th>
            <th>Artikelname</th>
            <th>Anzahl</th>
            <th>Stornieren</th>
            <th>Steuern</th>
            <th>Preis</th>
            <th>Gesamt</th>
          </thead>
          <tbody>
            <tr v-for="(storno, index) in stornos" :key="storno.id">
              <td>{{ storno.articleordernumber }}</td>
              <td>{{ storno.name }}</td>
              <td>{{ storno.quantity }}</td>
              <td>
                <select class="form-control form-control-sm" :disable="(storno.quantity - storno.canceled) >= 0" v-model="stornos[index].qty_sto">
                  <option value="0">0</option>
                  <option v-for="n in (storno.quantity - storno.canceled)" :value="n" :key="n">{{ n }} </option>
                </select>
              
              <td>{{ storno.tax_rate }} %</td>
              <td>{{ storno.price | toCurrency }}</td>
              <td>-{{ storno.qty_sto }}</td>
            </tr>
          </tbody>
        </table>
      </div> -->
    </div>

    <!-- <div class="row">
        <div class="col-md-12">
            <button class="btn btn-primary float-right" @click="saveOrder">Speichern</button>
        </div>
    </div> -->

    <!-- <b-modal id="articleModal" title="Artikel hinzufügen" ok-only ok-variant="danger" ok-title="Speichern" size="xl">
      <div class="row">
        <div class="col-md-12">
          <div class="input-group input-group-sm">
              <input type="text" class="form-control" placeholder="Suchen" @keyup.enter="getAllArticles" v-model="search" />
              <button type="submit" class="btn btn-default" @click.prevent="getAllArticles">
                  <i class="fas fa-search"></i>
              </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <table class="table table-striped">
              <thead>
                  <tr data-widget="expandable-table" aria-expanded="false">
                      <th>
                          <a href="#" @click.prevent="changeSort('ordernumber')">Artikelnummer</a>
                          <span v-if="this.params.sort_field == 'ordernumber' && this.params.sort_direction == 'asc'" >&uarr;</span>
                          <span v-if="this.params.sort_field == 'ordernumber' && this.params.sort_direction == 'desc'" >&darr;</span>
                      </th>
                      <th>
                          <a href="#" @click.prevent="changeSort('name')">Artikelname</a>
                          <span v-if="this.params.sort_field == 'name' && this.params.sort_direction == 'asc'" >&uarr;</span>
                          <span v-if="this.params.sort_field == 'name' && this.params.sort_direction == 'desc'" >&darr;</span>
                      </th>
                      <th><a href="#" @click.prevent="changeSort('producer')">Hersteller</a>
                          <span v-if="this.params.sort_field == 'producer' && this.params.sort_direction == 'asc'" >&uarr;</span>
                          <span v-if="this.params.sort_field == 'producer' && this.params.sort_direction == 'desc'" >&darr;</span></th>
                      <th>
                          <a href="#" @click.prevent="changeSort('price')">Preis</a>
                          <span v-if="this.params.sort_field == 'price' && this.params.sort_direction == 'asc'" >&uarr;</span>
                          <span v-if="this.params.sort_field == 'price' && this.params.sort_direction == 'desc'" >&darr;</span>
                      </th>
                      <th>
                          <a href="#" @click.prevent="changeSort('tax')">Steuersatz</a>
                          <span v-if="this.params.sort_field == 'tax' && this.params.sort_direction == 'asc'" >&uarr;</span>
                          <span v-if="this.params.sort_field == 'tax' && this.params.sort_direction == 'desc'" >&darr;</span>
                      </th>
                      <th style="width:15%; text-align:center;">Aktionen</th>
                  </tr>
                  <tr class="expandable-body">
                      <th>&nbsp;</th>
                      <th>&nbsp;</th>
                      <th>
                          <select v-model="params.producer_id" class="form-control form-control-sm">
                              <option :value="''">-- Filter Hersteller --</option>
                              <option v-for="producer in producers" :key="producer.id" :value="producer.id">{{ producer.name }}</option>
                          </select>
                      </th>
                      <th>&nbsp;</th>
                      <th>
                          <select v-model="params.tax_id" class="form-control form-control-sm">
                              <option :value="''">-- Filter Steuer --</option>
                              <option v-for="tax in taxes" :key="tax.id" :value="tax.id">{{ tax.description }}</option>
                          </select></th>
                      <th>&nbsp;</th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="(article,index) in articles.data" :key="article.id">
                      <td>{{ article.ordernumber }}</td>
                      <td>{{ article.name }}</td>
                      <td>{{ article.producer }}</td>
                      <td>{{ article.price | toCurrency}}</td>
                      <td>{{ article.tax }}</td>
                      <td style="text-align:center;">
                          
                          <b-button size="sm" @click="addArticle(index)" variant="primary"><i class="fas fa-plus"></i>Hinzufügen</b-button>
                      </td>
                  </tr>
              </tbody>
          </table>
          <div class="clearfix align-middle card-footer">
              <pagination class="float-left" :data="articles" @pagination-change-page="getAllArticles" :limit="3"></pagination>
              
              <select class="float-right form-control-sm" v-model="params.per_page">
                  <option value="25">25 Artikel</option>
                  <option value="50">50 Artikel</option>
                  <option value="75">75 Artikel</option>
                  <option value="100">100 Artikel</option>
                  <option value="125">125 Artikel</option>
                  <option value="150">150 Artikel</option>
              </select>
              <p v-if="notEmptyObject(articles)" class="float-right mr-2">Anzeige Eintrag {{ articles.meta.from }} - {{ articles.meta.to }} von {{ articles.meta.total }}</p>
          </div>
        </div>
      </div>
    </b-modal> -->

  </div>
</template>

<script>
export default {
  name: 'OrderDetailsItems',
  props: [
      'order'
  ],

  data(){
    return {
      taxes: [],
      selectedRow: {},
      selectedCell: null,
      selectedItems: [],
      producers: {},
      stornoShipping: false,
      articles: {},
      stornos: [],
      comment: "",
      stornos_additional: [],
      storno_total: 0,
      storno_total_netto: 0,
      storno_final: {
        order_id: '',
        comment: '',
        status_id: '',
        invoice_amount: '',
        invoice_amount_net: '',
        items: [],
      },
      allow_storno: false,
      params: {
                sort_field: 'ordernumber',
                sort_direction: 'asc',
                producer_id: '',
                tax_id: '',
                per_page: 25,
                category_id: '',
            },
            search: '',
    }
  },

  watch: {
        params: {
            handler () {
                this.getAllArticles();
            },
            deep: true
        },

        stornos: {
          handler() {
              this.generateTotalStorno();
              this.checkStorno();
          },
          deep: true
        },

        stornos_additional: {
          handler() {
              this.generateTotalStorno();
              this.checkStorno();
          },
          deep: true
        },

        stornoShipping: function() {
          this.generateTotalStorno();
          this.checkStorno();
        },

    },

  computed: {
        selectAll: {
            get: function () {
                return this.order.items ? this.selectedItems.length == this.order.items.length : false;
            },
            set: function (value) {
                var selected = [];

                if(value) {
                    this.order.items.forEach(function (item) {
                        selected.push(item.id);
                    });
                }

                this.selectedItems = selected;
            }
        }
    },

  methods:{
    checkStorno(){
      for(let i = 0; i < this.stornos.length; i++){
        if(this.stornos[i].qty_sto > 0)
        {
          this.allow_storno = true;
          // break;
          return;
        }
      }

      if(this.stornos_additional.length > 0)
      {
        this.allow_storno = true;
        return;
      }

      if(this.stornoShipping == true)
      {
        this.allow_storno = true;
        return;
      }

      this.allow_storno = false;
      return;
    },


    doCorrection(){
      this.storno_final.order_id = this.order.id;
      this.storno_final.comment = this.comment;
      this.storno_final.status_id = 1;
      this.storno_final.invoice_amount = this.storno_total;
      this.storno_final.invoice_amount_net = this.storno_total_netto;
      this.storno_final.items = [];

      this.stornos.forEach(function(item){
        if(item.qty_sto > 0)
        {
          this.storno_final.items.push({
            article_id: item.article_id,
            article_detail_id: item.article_detail_id,
            articleordernumber: item.articleordernumber,
            price:item.price * -1,
            quantity: item.quantity * -1,
            name: item.name,
            tax_id: item.tax_id,
            tax_rate: item.tax_rate,
            order_item_id: item.order_item_id,
          });
        }
      },this);

      this.stornos_additional.forEach(function (item){
        var tax = this.taxes.find(taxx => taxx.tax == item.tax_rate);
        var price = 0;
        if(item.type == 'normal')
        {
          //Normaler Artikel = Preis in Brutto und fertig
          price = item.price;
        }

        if(item.type == 'deposit')
        {
          //Prüfe nun ob es ein Firmen oder Privatkunde ist
          if(this.order.customer.group.tax == 1)
          {
            //Kunde ist ein Privatkunde
            price = item.price;
          }

          if(this.order.customer.group.tax == 0)
          {
            //Kunde ist Firmenkunde. Pfand ist plus Mwst
            price = ((parseFloat(item.price) / 100) * (100 + parseFloat(item.tax_rate)));
          }
        }

        if(item.quantity < 0)
        {
          price = price * -1;
        }

        this.storno_final.items.push({
            article_id: 0,
            article_detail_id: 0,
            articleordernumber: '',
            price:price,
            quantity: item.quantity,
            name: item.name,
            tax_id: tax.id,
            tax_rate: item.tax_rate,
        });
      },this);

      if(this.stornoShipping == true)
      {
        var tax_shipping = this.taxes.find(taxx => taxx.tax == this.order.invoice_shipping_tax_rate);
        this.storno_final.items.push({
            article_id: 0,
            article_detail_id: 0,
            articleordernumber: '',
            price: this.order.invoice_shipping * -1,
            quantity: -1,
            name: 'Stornierung Versandkosten',
            tax_id: tax_shipping.id,
            tax_rate: this.order.invoice_shipping_tax_rate,
            shipping: true,
        });
      }

      this.axios
        .post("/orders/stornos", this.storno_final)
        .then(() => {
            this.$swal({
                icon: "success",
                title: "Artikel wurden storniert",
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
            });
            //this.$router.push({name: 'orders.index'});
            setTimeout(() => {
                  this.$bvModal.hide("stornoModal");
                  this.$parent.getOrder();
                  this.loading = false;
                }, 1000);
        })
        .catch(() => {
            this.$swal({
                icon: "error",
                title: "Oops...",
                text: "Da ist etwas schief gelaufen",
            });
        });

    },

    removeAdditionalStorno(index){
      this.$delete(this.stornos_additional, index);
    },

    addAdditionalStorno(){
      this.stornos_additional.push({
        id: Math.floor(Math.random() * 101),
        name: 'Freie Position',
        quantity: 1,
        tax_rate: '19.00',
        price: 1,
        type: 'normal',
        article_id: 0,
        article_detail_id: 0,
      });
    },

    addAdditionalDeposit(){
      this.stornos_additional.push({
        id: Math.floor(Math.random() * 101),
        name: 'Pfandrückgabe',
        quantity: -1,
        tax_rate: '19.00',
        price: 1,
        type: 'deposit',
        article_id: 0,
        article_detail_id: 0,
      });
    },

    generateTotalStorno()
    {
      this.storno_total = 0;
      this.storno_total_netto = 0;

      this.stornos.forEach(function (storno){
        this.storno_total -= storno.qty_sto * storno.price;
        this.storno_total_netto -= storno.qty_sto * ((parseFloat(storno.price) / (100 + parseFloat(storno.tax_rate))) * 100)
      },this);

      this.stornos_additional.forEach(function (storno){
        //Prüfe ob es ein normaler Artikel ist.
        if(storno.type == 'normal')
        {
          // Es ist ein normaler Artikel
          this.storno_total += storno.quantity * storno.price;
          this.storno_total_netto += storno.quantity * ((parseFloat(storno.price) / (100 + parseFloat(storno.tax_rate))) * 100)
        }

        if(storno.type == 'deposit')
        {
          //Prüfe ob es sich um einen Firmen oder Privatkunden handelt.
          if(this.order.customer.group.tax == 1)
          {
            //Kunde ist ein Privatkunde
            this.storno_total += storno.quantity * storno.price;
            this.storno_total_netto += storno.quantity * ((parseFloat(storno.price) / (100 + parseFloat(storno.tax_rate))) * 100)
          }

          if(this.order.customer.group.tax == 0)
          {
            //Kunde ist Firmenkunde. Pfand ist plus Mwst
            this.storno_total += storno.quantity * ((parseFloat(storno.price) / 100) * (100 + parseFloat(storno.tax_rate)));
            this.storno_total_netto += storno.quantity * storno.price;
          }
        }
        
      },this);

      if(this.stornoShipping == true)
      {
        this.storno_total -= this.order.invoice_shipping;
        this.storno_total_netto -= this.order.invoice_shipping_net;
      }
    },

    // stornoItem(id){
    //   var article = this.order.items.find(data => data.id == id);
    //   // article.qty_sto = 0;
    //   this.stornos.push({
    //     articleordernumber: article.articleordernumber,
    //     name: article.name,
    //     quantity: article.quantity,
    //     canceled: article.canceled,
    //     tax_rate: article.tax_rate,
    //     price: article.price,
    //     article_id: article.article_id,
    //     article_detail_id: article.article_detail_id,
    //     tax_id: article.tax_id,
    //   });
    // },

    handleModal(){
      this.stornos = [];
      this.order.items.forEach(function (item) {
          var article = this.order.items.find(data => data.id == item.id);
          if(article.quantity - article.canceled > 0)
          {
            this.stornos.push({
              articleordernumber: article.articleordernumber,
              name: article.name,
              quantity: article.quantity,
              canceled: article.canceled,
              tax_rate: article.tax_rate,
              price: article.price,
              qty_sto: 0,
              tax_id: article.tax_id,
              article_id: article.article_id,
              article_detail_id: article.article_detail_id,
              order_item_id: article.id,
            });
          }
      }, this);
      this.$bvModal.show("stornoModal");
    },

    addArticle(index){
      var article = this.articles.data[index];
      this.order.items.push({
        'articleordernumber': article.details.ordernumber,
        'name': article.name,
        'quantity': 1,
        'price': article.price,
        'tax_rate': article.tax_rate,
        'article_id': article.id,
        'article_detail_id': article.details.id,
        'order_id': this.order.id,
        'ordernumber': this.order.ordernumber,
        'id': null,
      });
      this.$bvModal.hide("articleModal");
    },

    addArticleModal(){
      this.$bvModal.show("articleModal");
    },

    changeSort(field) {
            if(this.params.sort_field === field) {
                this.params.sort_direction = this.params.sort_direction == 'asc' ? 'desc' : 'asc';
            }
            else{
                this.params.sort_field = field;
                this.params.sort_direction = 'asc';
            }
        },

        getAllArticles(page = 1) {
            this.$Progress.start();
            this.axios
                .get("/articles", {
                    params: {
                        page,
                        search: this.search.length >= 4 ? this.search : '',
                        ...this.params
                    }
                })
                .then((response) => {
                    this.articles = response.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        getProducers() {
            this.$Progress.start();
            this.axios
                .get("/producers")
                .then((response) => {
                    this.producers = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

    addDepositReturn(){
      this.order.items.push({
        'articleordernumber': 'Pfand',
        'name': 'Pfandrückgabe',
        'quantity': 1,
        'price': 0,
        'tax_rate': '19.00',
        'article_id': 0,
        'article_detail_id': 0,
        'order_id': this.order.id,
        'ordernumber': this.order.ordernumber,
        'id': null,
      });
    },


    removeItem(index){
      this.$delete(this.order.items, index)
    },

    addPosition(){
      this.order.items.push({
        'articleordernumber': 'Freie Position',
        'name': 'Gib einen Namen ein',
        'quantity': 1,
        'price': 0,
        'tax_rate': '7.00',
        'article_id': 0,
        'article_detail_id': 0,
        'order_id': this.order.id,
        'ordernumber': this.order.ordernumber,
        'id': null,
      });
    },
    groupBy(array, key){
      const result = {}
      array.forEach(item => {
        if (!result[item[key]]){
          result[item[key]] = []
        }
        result[item[key]].push(item)
      })
      return result
    },

    saveOrder(){
      // 1) Ich muss die MwSt Satz für den Versand neu berechnen.
      var taxes = this.groupBy(this.order.items, 'tax_rate');
      var data =  [];
      for (const [key, value] of Object.entries(taxes)) {

        let newObject = {
          rate: key,
          value: (value.reduce((acc, item) => acc + (item.price * item.quantity), 0))
        }
        data.push(newObject);
      }

      var tax_rate = data.reduce((max, obj) => (max.value > obj.value) ? max : obj);
      var invoice_shipping_tax_rate = tax_rate.rate;


      // 2) Berechne die Netto Versandkosten erneut.
      var invoice_shipping_net = ((this.order.invoice_shipping / (100 + parseFloat(invoice_shipping_tax_rate))) * 100); 

      // 3) Berechene den Grundbetrag der Rechnung. Brutto sowie Netto

      var invoice_amount = (this.order.items.reduce((acc, item) => acc + (item.price * item.quantity), 0)) + this.order.invoice_shipping;
      var invoice_amount_net = (this.order.items.reduce((acc, item) => acc + (((item.price * item.quantity) / (100 + parseFloat(item.tax_rate)) * 100)), 0)) + invoice_shipping_net;
      
      this.$Progress.start();
      this.axios
          .put("/orders/" + this.order.id, {
            'invoice_shipping_tax_rate': parseFloat(invoice_shipping_tax_rate).toFixed(2),
            'invoice_shipping_net': invoice_shipping_net,
            'invoice_amount': invoice_amount,
            'invoice_amount_net': invoice_amount_net,
            'items': this.order.items,
          })
          .then(() => {
              // this.billingStates = response.data.data;
              this.$parent.getOrder();
              this.$Progress.finish();
          })
          .catch(() => {
              this.$swal({
                  icon: "error",
                  title: "Oops...",
                  text: "Da ist etwas schief gelaufen",
              });
              this.$Progress.fail();
          });
    },

    closeEdit(){
      this.selectedRow = {};
      this.selectedCell = '';
    },

    handleEditRow(index) {
        this.selectedRow = {
          [index]: !this.selectedRow[index]
        }
    },

    handleEditCell(index, name) {
        this.selectedCell = name;
        this.selectedRow = {
          [index]: !this.selectedRow[index]
        }
    },

    loadTaxes(){
      this.$Progress.start();
      this.axios
          .get("/taxes")
          .then((response) => {
              this.taxes = response.data.data;
              this.$Progress.finish();
          })
          .catch(() => {
              this.$swal({
                  icon: "error",
                  title: "Oops...",
                  text: "Da ist etwas schief gelaufen",
              });
              this.$Progress.fail();
          });
    }
  },

  mounted() {
    this.loadTaxes();
    this.getAllArticles();
    this.getProducers();
  }
}
</script>

<style>

</style>